/**
 * @overview Core API, version dev
 */
/**
 * OpenAPI path: #/components/schemas/area.Policy_PolicyType
 */
export enum PolicyPolicyType {
  NotSet = 0,
  SpeedLimit = 1,
}

/**
 * OpenAPI path: #/components/schemas/area.ServiceableArea_Status
 */
export enum ServiceableAreaStatus {
  NotSet = 0,
  Active = 1,
  Inactive = 2,
  Deleted = 4,
}

/**
 * OpenAPI path: #/components/schemas/auth.OTPMode
 */
export enum OtpMode {
  Phone = 0,
  Email = 1,
}

/**
 * OpenAPI path: #/components/schemas/base.LockSource
 */
export enum LockSource {
  Unknown = 0,
  Bluetooth = 1,
  Lte = 2,
  AutolockTimeout = 3,
  AutolockStack = 4,
  RfidBypass = 5,
  AutolockStackMaintenance = 6,
  PauseInactivity = 7,
  AutolockStackFallback = 8,
  AutolockDocked = 9,
  HeavyLockFailure = 10,
  Rfid = 11,
  AutolockNotUnstacked = 12,
  Manual = 13,
}

/**
 * OpenAPI path: #/components/schemas/bike.BatteryManufacturer
 */
export enum BatteryManufacturer {
  Greenway = 0,
  Portapower = 1,
  Unknown = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.BatteryStatus
 */
export enum BatteryStatus {
  Started = 0,
  Stopped = 1,
  Absent = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.Bike_Accessibility
 */
export enum BikeAccessibility {
  Pickup = 0,
  Delivery = 1,
  Spare = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.Bike_Availability
 */
export enum BikeAvailability {
  AvailableForSharing = 0,
  AvailableForLeasing = 1,
}

/**
 * OpenAPI path: #/components/schemas/bike.Bike_LastContactEntityType
 */
export enum BikeLastContactEntityType {
  LastcontactentitytypeNotSet = 0,
  Bike = 1,
  Phone = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.Bike_Status
 */
export enum BikeStatus {
  NotSet = 0,
  Available = 1,
  Booked = 2,
  InUse = 3,
  Paused = 4,
  Leased = 5,
}

/**
 * OpenAPI path: #/components/schemas/bike.BoardType
 */
export enum BoardType {
  UnknownBoard = 0,
  Cpu = 1,
  Comm = 2,
  Bridge = 3,
  BridgeTop = 4,
  Power = 5,
}

/**
 * OpenAPI path: #/components/schemas/bike.Comment_CommentType
 */
export enum CommentCommentType {
  NotSet = 0,
  Default = 1,
  RootCause = 2,
  Solution = 3,
  AutoGenerated = 4,
}

/**
 * OpenAPI path: #/components/schemas/bike.Dock_ChargerState
 */
export enum DockChargerState {
  NotPresent = 0,
  Stopped = 1,
  Started = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.Dock_DockCategory
 */
export enum DockDockCategory {
  NotDocked = 0,
  Standard = 1,
  Electrical = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.Dock_DockStatus
 */
export enum DockDockStatus {
  Unknown = 0,
  Docked = 1,
  Undocked = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.Hardware
 */
export enum HardwareBoardType {
  UnknownBoard = 0,
  Cpu = 1,
  Comm = 2,
  Bridge = 3,
  BridgeTop = 4,
  Power = 5,
  ZoovboxMain = 6,
  ZoovboxTouch = 7,
  EdockMain = 8,
  EdockDetect = 9,
  FlexCom = 10,
  Rfid = 11,
  HeavyLock = 12,
  Dynamo = 13,
}

/**
 * OpenAPI path: #/components/schemas/bike.Hardware
 */
export enum HardwareProjectType {
  ProjectUnknown = 0,
  ProjectMain01 = 1,
  ProjectController01 = 2,
  ProjectMain02 = 3,
  ProjectController02 = 4,
  ProjectZoovbox01 = 5,
  ProjectMainbox01 = 6,
  ProjectFlex = 7,
  ProjectController03 = 8,
  ProjectRfid01 = 9,
  ProjectHeavyLock01 = 10,
  ProjectMain04 = 11,
  ProjectRfid02 = 12,
  ProjectTestStation = 13,
  ProjectDynamo01 = 14,
}

/**
 * OpenAPI path: #/components/schemas/bike.LockedStatus
 */
export enum BikeLockedStatus {
  LockedstatusNotSet = 0,
  Locked = 1,
  Unlocked = 2,
  SoftUnlocked = 3,
}

/**
 * OpenAPI path: #/components/schemas/bike.LostInfo_Status
 */
export enum LostInfoStatus {
  NotSet = 0,
  Tracked = 1,
  InSearch = 2,
  Lost = 3,
}

/**
 * OpenAPI path: #/components/schemas/bike.MaintenanceState
 */
export enum BikeMaintenanceState {
  NotSet = 0,
  InField = 1,
  NeedFieldMaintenance = 2,
  Recycled = 3,
  NeedWarehouseLvl2 = 4,
  InTruckLvl1 = 5,
  InTruckLvl2 = 6,
  InWarehouseLvl1 = 7,
  InWarehouseLvl2 = 8,
  ToSetup = 9,
  InWarehouseExitZone = 10,
  InTruckForReallocation = 11,
  InWarehouseLvl1Plus = 12,
  InWarehouseLvl3 = 13,
  ToRecycle = 14,
  NeedWarehouse = 15,
  InTruckToWarehouse = 16,
  EntryZone = 17,
  PreExitZone = 18,
}

/**
 * OpenAPI path: #/components/schemas/bike.Os
 */
export enum Os {
  UnknownOs = 0,
  Ios = 1,
  Android = 2,
  Linux = 3,
}

/**
 * OpenAPI path: #/components/schemas/bike.PowerSavingMode
 */
export enum PowerSavingMode {
  ModeNone = 0,
  ModeLowPower = 1,
  ModeDeepSleep = 2,
}

/**
 * OpenAPI path: #/components/schemas/bike.PowerTransfer_LastElectionResult
 */
export enum PowerTransferLastElectionResult {
  Unknown = 0,
  Not_30VCompatible = 1,
  LastAndLowBatt = 2,
  LastAndHighBatt = 3,
  LastAndNotChargeAllowed = 4,
  MiddleInStack = 5,
  RankTooHigh = 6,
  NotInStack = 7,
}

/**
 * OpenAPI path: #/components/schemas/bike.ProjectType
 */
export enum ProjectType {
  UnknownProject = 0,
  Main01 = 1,
  Controller01 = 2,
  Main02 = 3,
  Controller02 = 4,
}

/**
 * OpenAPI path: #/components/schemas/bike.Stack_ProductCategory
 */
export enum StackProductCategory {
  NotSet = 0,
  Bike = 1,
  ElectricStation = 2,
  PassiveStation = 3,
  PassiveMaintenanceStation = 4,
}

/**
 * OpenAPI path: #/components/schemas/bike_state.BikeStateError
 */
export enum BikeStateError {
  NoError = 0,
  ErrorModeFailSafe = 1,
  ErrorToFastToLock = 2,
  ErrorNoTimeoutOutsideOfTrip = 3,
  ErrorLockBusy = 4,
  ErrorSentAtTimestampTooOld = 5,
  ErrorStackedLeft = 6,
  ErrorLockInTrip = 7,
  ErrorForbiddenState = 8,
}

/**
 * OpenAPI path: #/components/schemas/bike_state.BikeStateSource
 */
export enum BikeStateSource {
  SourceUnknown = 0,
  SourceBle = 1,
  SourceLwm2M = 2,
  SourceAutolockTimeout = 3,
  SourceAutolockStack = 4,
  SourceRfid = 5,
  SourceAutolockStackMaintenance = 6,
  SourcePauseInactivity = 7,
  SourceAutolockStackFallback = 8,
  SourceAutolockDocked = 9,
}

/**
 * OpenAPI path: #/components/schemas/bike_state.LockState
 */
export enum LockState {
  Unchanged = 0,
  Locked = 1,
  Unlocked = 2,
  SoftUnlocked = 3,
}

/**
 * OpenAPI path: #/components/schemas/bike_state.MaintenanceState
 */
export enum BikeStateMaintenanceState {
  Unchanged = 0,
  InOrder = 1,
  MaintenanceRequired = 2,
  InTruck = 3,
  InWarehouse = 4,
}

/**
 * OpenAPI path: #/components/schemas/bike_state.TripState
 */
export enum TripState {
  Unchanged = 0,
  Available = 1,
  Booked = 2,
  InTrip = 3,
  InPause = 4,
}

/**
 * OpenAPI path: #/components/schemas/entity.AccessTechnology
 */
export enum AccessTechnology {
  NotSet = 0,
  _2G = 10,
  _4G = 20,
  _4GNbIot = 21,
  _4GCatM1 = 22,
}

/**
 * OpenAPI path: #/components/schemas/entity.InOrderStatus
 */
export enum InOrderStatus {
  Unknown = 0,
  InOrder = 1,
  OutOfOrder = 2,
}

/**
 * OpenAPI path: #/components/schemas/entity.LockedStatus
 */
export enum EntityLockedStatus {
  NotSet = 0,
  Locked = 1,
  Unlocked = 2,
  SoftUnlocked = 3,
}

/**
 * OpenAPI path: #/components/schemas/entity.LostStatus
 */
export enum LostStatus {
  LoststatusNotSet = 0,
  Tracked = 1,
  InSearch = 2,
  Lost = 3,
}

/**
 * OpenAPI path: #/components/schemas/entity.RentalType
 */
export enum RentalType {
  Undefined = 0,
  Sharing = 1,
  Leasing = 2,
}

/**
 * OpenAPI path: #/components/schemas/entity.StackCategory
 */
export enum StackCategory {
  NotSet = 0,
  Vehicle = 1,
  ElectricStack = 2,
  PassiveStack = 3,
  PassiveMaintenanceStack = 4,
}

/**
 * OpenAPI path: #/components/schemas/entity.VehicleMaintenanceState
 */
export enum VehicleMaintenanceState {
  MaintenanceStateUnknown = 0,
  MaintenanceStateInOrder = 1,
  MaintenanceStateInOrderNeedsMaintenance = 2,
  MaintenanceStateOutOfOrderNeedsMaintenance = 11,
  MaintenanceStateOutOfOrderNeedsWarehouse = 12,
  MaintenanceStateInTruckToWarehouse = 21,
  MaintenanceStateInTruckForReallocation = 22,
  MaintenanceStateInWarehouseEntry = 31,
  MaintenanceStateInWarehouse_1 = 41,
  MaintenanceStateInWarehouse_2 = 42,
  MaintenanceStateInWarehouse_3 = 43,
  MaintenanceStateInWarehouse_4 = 44,
  MaintenanceStateInWarehousePreExit = 51,
  MaintenanceStateInWarehouseExit = 52,
  MaintenanceStateToRecycle = 61,
  MaintenanceStateRecycled = 62,
  MaintenanceStateToSetup = 71,
  MaintenanceStateToDeploy = 72,
}

/**
 * OpenAPI path: #/components/schemas/fleet.ParkingType
 */
export enum ParkingType {
  Unknown = 0,
  Stack = 1,
  Dock = 2,
  Virtual = 3,
  Nearby = 4,
}

/**
 * OpenAPI path: #/components/schemas/fleet.ProductVersion_PropulsionType
 */
export enum ProductVersionPropulsionType {
  NotSet = 0,
  Human = 1,
  ElectricAssist = 2,
  Electric = 3,
  Combustion = 4,
  CombustionDiesel = 5,
  Hybrid = 6,
  HydrogenFuelCell = 7,
  PlugInHybrid = 8,
}

/**
 * OpenAPI path: #/components/schemas/fleet.ProductVersion_VehicleType
 */
export enum ProductVersionVehicleType {
  NotSet = 0,
  Bicycle = 1,
  Bus = 2,
  CargoBicycle = 3,
  Car = 4,
  DeliveryRobot = 5,
  Moped = 6,
  Motorcycle = 7,
  ScooterStanding = 8,
  ScooterSeated = 9,
  Truck = 10,
  Other = 11,
}

/**
 * OpenAPI path: #/components/schemas/fleet.SystemState
 */
export enum SystemState {
  NotSet = 0,
  Inventory = 1,
  Active = 2,
  TestReady = 3,
  Suspended = 4,
  Retired = 5,
}

/**
 * OpenAPI path: #/components/schemas/gateway_omni.AlarmType
 */
export enum AlarmType {
  UnknownAlarmType = 0,
  IllegalMovement = 1,
  Fall = 2,
  FallCleared = 6,
}

/**
 * OpenAPI path: #/components/schemas/gateway_omni.LocationMode
 */
export enum LocationMode {
  UnknownLocationMode = 0,
  Invalid = 1,
  Autonomous = 2,
  Difference = 3,
}

/**
 * OpenAPI path: #/components/schemas/gateway_omni.LockedStatus
 */
export enum GatewayOmniLockedStatus {
  Unlocked = 0,
  Locked = 1,
}

/**
 * OpenAPI path: #/components/schemas/gateway_omni.Omni_DynamoGroup1_ChargeMode
 */
export enum OmniDynamoGroup1ChargeMode {
  LowPower = 0,
  DynamoCharge = 1,
  OmniCharge = 2,
  DynamoAndOmniCharge = 3,
}

/**
 * OpenAPI path: #/components/schemas/gateway_vehicle.AssistLevel
 */
export enum AssistLevel {
  Unknown = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}

/**
 * OpenAPI path: #/components/schemas/gateway_vehicle.AssistState
 */
export enum AssistState {
  Unknown = 0,
  On = 1,
  Off = 2,
}

/**
 * OpenAPI path: #/components/schemas/gateway_vehicle.FileType
 */
export enum FileType {
  Unknown = 0,
  Logs = 1,
  RawData = 2,
}

/**
 * OpenAPI path: #/components/schemas/gateway_vehicle.LockOperationType
 */
export enum LockOperationType {
  LockOperationUnknown = 0,
  LockOperationPause = 1,
  LockOperationEnd = 2,
  LockOperationForceEnd = 3,
}

/**
 * OpenAPI path: #/components/schemas/gateway_vehicle.PlayType
 */
export enum PlayType {
  None = 0,
  Success = 1,
  UserError = 2,
  VehicleError = 3,
  Unlock = 4,
  SoftUnlock = 5,
  Lock = 7,
  Start = 8,
  End = 9,
  Pause = 10,
  Resume = 11,
  Search = 12,
  AreaExit = 13,
  AreaEntrance = 14,
  SpeedLimitDown = 15,
  SpeedLimitUp = 16,
  LowBattery = 17,
  SearchLight = 18,
  SearchSound = 19,
}

/**
 * OpenAPI path: #/components/schemas/gateway_vehicle.UnlockOperationType
 */
export enum UnlockOperationType {
  UnlockOperationUnknown = 0,
  UnlockOperationStart = 1,
  UnlockOperationResume = 2,
  UnlockOperationSoftUnlock = 3,
  UnlockOperationMaintenanceUnlock = 4,
}

/**
 * OpenAPI path: #/components/schemas/gateway_wirma.IdentType
 */
export enum IdentType {
  NotSet = 0,
  Card = 1,
  Code = 2,
}

/**
 * OpenAPI path: #/components/schemas/healthcheck.Status
 */
export enum Status {
  Unknown = 0,
  Up = 1,
  Down = 2,
}

/**
 * OpenAPI path: #/components/schemas/issue.CommentType
 */
export enum CommentType {
  NotSet = 0,
  Default = 1,
  RootCause = 2,
  Solution = 3,
  AutoGenerated = 4,
}

/**
 * OpenAPI path: #/components/schemas/issue.Issue_Severity
 */
export enum IssueSeverity {
  NotSet = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}

/**
 * OpenAPI path: #/components/schemas/issue.Issue_Status
 */
export enum IssueStatus {
  NotSet = 0,
  Open = 1,
  Closed = 2,
  Archived = 3,
}

/**
 * OpenAPI path: #/components/schemas/issue.Issue_Target
 */
export enum IssueTarget {
  NotSet = 0,
  Bike = 1,
  Fastener = 2,
  Station = 3,
  Vehicle = 4,
}

/**
 * OpenAPI path: #/components/schemas/notifier.SmsProvider
 */
export enum SmsProvider {
  Unknown = 0,
  Twilio = 1,
  Mtarget = 2,
}

/**
 * OpenAPI path: #/components/schemas/payment.Benefit_Status
 */
export enum BenefitStatus {
  NotSet = 0,
  Active = 1,
  NeedsPayment = 2,
  PendingFirstPayment = 3,
  Ended = 4,
  Paused = 5,
  PendingValidation = 6,
  BillingPendingValidation = 7,
}

/**
 * OpenAPI path: #/components/schemas/payment.ChallengeStatus
 */
export enum ChallengeStatus {
  Unset = 0,
  Done = 1,
  Fail = 2,
  Errored = 3,
  Waiting_3DsMethod = 4,
  WaitingChallenge = 5,
}

/**
 * OpenAPI path: #/components/schemas/payment.DeliveryPlace_Type
 */
export enum DeliveryPlaceType {
  Undefined = 0,
  DeliveryBattery = 4,
  DeliveryProduct = 5,
  ReturnProduct = 6,
}

/**
 * OpenAPI path: #/components/schemas/payment.Discount_Status
 */
export enum DiscountStatus {
  DiscountActive = 0,
  DiscountPendingActivation = 1,
  DiscountNeedsPayment = 2,
  DiscountCanceled = 3,
  DiscountPendingPayment = 4,
  DiscountDisabled = 5,
}

/**
 * OpenAPI path: #/components/schemas/payment.DoActionStatus
 */
export enum DoActionStatus {
  Unset = 0,
  NoActionRequired = 1,
  ActionRequired = 2,
  Fail = 3,
}

/**
 * OpenAPI path: #/components/schemas/payment.OfferPolicy_Type
 */
export enum OfferPolicyType {
  NotSet = 0,
  BenefitsLimit = 1,
  BenefitsPerTargetLimit = 2,
}

/**
 * OpenAPI path: #/components/schemas/payment.Offer_ObtentionMethodType
 */
export enum OfferObtentionMethodType {
  Undefined = 0,
  SingleImmediateCapture = 1,
  Subscription = 2,
  SingleDeferredCapture = 3,
  Manual = 4,
  Pack = 5,
}

/**
 * OpenAPI path: #/components/schemas/payment.Offer_Type
 */
export enum OfferType {
  Undefined = 0,
  SharingPricing = 1,
  LeasingPricing = 2,
  Discount = 3,
  Pack = 4,
}

/**
 * OpenAPI path: #/components/schemas/payment.PaymentMethodType
 */
export enum PaymentMethodType {
  Unknown = 0,
  Card = 1,
  GooglePay = 2,
  ApplePay = 3,
  Paypal = 4,
}

/**
 * OpenAPI path: #/components/schemas/payment.PaymentStatus
 */
export enum PaymentStatus {
  PaymentSuccessfull = 0,
  PaymentNeedsAction = 1,
  PaymentNeedsMethod = 2,
  PaymentCannotBeUsed = 3,
  PaymentNeedsCapture = 4,
  PaymentReady = 5,
}

/**
 * OpenAPI path: #/components/schemas/payment.PaymentType
 */
export enum PaymentType {
  Trip = 0,
  Wallet = 1,
  Adjustment = 2,
  SetupMethod = 3,
}

/**
 * OpenAPI path: #/components/schemas/payment.Pricing_RulesRefillCycle
 */
export enum PricingRulesRefillCycle {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
}

/**
 * OpenAPI path: #/components/schemas/payment.Pricing_Type
 */
export enum PricingType {
  PricingTypeDefault = 0,
  PricingAsUserDiscount = 1,
  PricingAsUserPrepaidPack = 2,
  PricingAsRolePrepaidPack = 3,
  PricingAsUserSubscription = 4,
  PricingAsRoleSubscription = 5,
  PricingDisabled = 6,
  PricingAsLeasingSubscription = 7,
  PricingPass = 8,
  PricingPricedDiscount = 9,
}

/**
 * OpenAPI path: #/components/schemas/payment.Rule_Type
 */
export enum RuleType {
  Undefined = 0,
  Repeated = 1,
  Exempted = 2,
  TripConstraint = 3,
}

/**
 * OpenAPI path: #/components/schemas/payment.Subscription_Status
 */
export enum SubscriptionStatus {
  NotSet = 0,
  Active = 1,
  Ended = 2,
  NeedsPayment = 3,
  Paused = 4,
  PendingFirstPayment = 5,
}

/**
 * OpenAPI path: #/components/schemas/payment.TargetType
 */
export enum TargetType {
  Undefined = 0,
  User = 1,
  Role = 2,
  Station = 3,
  Bike = 4,
  Area = 5,
}

/**
 * OpenAPI path: #/components/schemas/product.LastUpdatedSource
 */
export enum LastUpdatedSource {
  NotSet = 0,
  BikeGps = 1,
  AppZoov = 2,
  WebappZoovAdmin = 3,
  BikeWifi = 4,
  AppZoovMaintenance = 5,
  Angels = 6,
  Discard = 7,
  Bike = 8,
}

/**
 * OpenAPI path: #/components/schemas/product_type.EntityType
 */
export enum EntityType {
  Unknown = 0,
  Station = 1,
  Parking = 2,
  Vehicle = 3,
  PointOfInterest = 4,
  UrbanFurniture = 5,
  Transmitter = 6,
}

/**
 * OpenAPI path: #/components/schemas/raw_data.GenerateGCSSignedURLRequest_BucketType
 */
export enum GenerateGcsSignedUrlRequestBucketType {
  Bike = 0,
  Phone = 1,
  BikeLogs = 2,
  DroidMisc = 3,
  IosMisc = 4,
}

/**
 * OpenAPI path: #/components/schemas/rental.Booking_Status
 */
export enum BookingStatus {
  NotSet = 0,
  Pending = 1,
  Honored = 2,
  Canceled = 3,
  Expired = 4,
  Error = 5,
}

/**
 * OpenAPI path: #/components/schemas/rental.Entity_FasteningType
 */
export enum EntityFasteningType {
  NotSet = 0,
  NotFastened = 1,
  Stacked = 2,
  Docked = 3,
}

/**
 * OpenAPI path: #/components/schemas/rental.Entity_ProductType
 */
export enum EntityProductType {
  UndefinedProductType = 0,
  Vehicle = 1,
  Station = 2,
}

/**
 * OpenAPI path: #/components/schemas/rental.GetDirectionsRequest_Profile
 */
export enum GetDirectionsRequestProfile {
  NotSet = 0,
  Walking = 1,
  Cycling = 2,
}

/**
 * OpenAPI path: #/components/schemas/rental.Rental_Status
 */
export enum RentalStatus {
  Undefined = 0,
  Active = 1,
  PendingPreAuth = 2,
  Ended = 3,
  NeedsPayment = 4,
  BillingPendingValidation = 5,
  PendingEnd = 6,
  BenefitNeedsPayment = 7,
  BenefitPaused = 8,
}

/**
 * OpenAPI path: #/components/schemas/rental.Risk
 */
export enum Risk {
  Unknown = 0,
  Suspicious = 1,
  SuspiciousUnchecked = 2,
  Cleared = 3,
  Malicious = 4,
  UnderInvestigation = 5,
  PendingAutomaticEvaluation = 6,
}

/**
 * OpenAPI path: #/components/schemas/rental.SuspiciousReason
 */
export enum SuspiciousReason {
  Unknown = 0,
  MaxDuration = 1,
  TripErrors = 2,
  LockSources = 3,
  MaxDistance = 4,
  EndOutsideStation = 5,
}

/**
 * OpenAPI path: #/components/schemas/rental.Trip_Rate
 */
export enum TripRate {
  UndefinedRate = 0,
  Bad = 1,
  Ok = 2,
  Top = 3,
}

/**
 * OpenAPI path: #/components/schemas/rental.Trip_Status
 */
export enum TripStatus {
  NotSet = 0,
  Pending = 1,
  Booked = 2,
  Started = 3,
  Ended = 4,
  Canceled = 5,
  Expired = 6,
  Error = 7,
  Paused = 8,
  BillingPendingValidation = 10,
}

/**
 * OpenAPI path: #/components/schemas/rental.Trip_Type
 */
export enum TripType {
  Undefined = 0,
  Sharing = 1,
  Leasing = 2,
}

/**
 * OpenAPI path: #/components/schemas/station.Availability
 */
export enum Availability {
  AvailableForSharing = 0,
  AvailableForLeasing = 1,
}

/**
 * OpenAPI path: #/components/schemas/station.FastenerType
 */
export enum FastenerType {
  Stack = 0,
  Virtual = 1,
  Dock = 2,
}

/**
 * OpenAPI path: #/components/schemas/station.Fastener_AllowedInOrderStatus
 */
export enum FastenerAllowedInOrderStatus {
  AllowedNotSet = 0,
  AllowedInOrder = 1,
  AllowedOutOfOrder = 2,
}

/**
 * OpenAPI path: #/components/schemas/station.Fastener_MaintenanceState
 */
export enum FastenerMaintenanceState {
  NotSet = 0,
  InField = 1,
  NeedMaintenanceInOrder = 2,
  NeedMaintenanceOutOfOrder = 3,
  UnderMaintenance = 4,
}

/**
 * OpenAPI path: #/components/schemas/station.Fastener_Status
 */
export enum FastenerStatus {
  NotSet = 0,
  Active = 1,
  Inactive = 2,
}

/**
 * OpenAPI path: #/components/schemas/station.PowerSource
 */
export enum PowerSource {
  None = 0,
  Battery = 1,
  Grid = 2,
  Sector = 3,
}

/**
 * OpenAPI path: #/components/schemas/station.Station_MaintenanceState
 */
export enum StationMaintenanceState {
  NotSet = 0,
  InField = 1,
  NeedMaintenanceInOrder = 2,
  NeedMaintenanceOutOfOrder = 3,
  UnderMaintenance = 4,
}

/**
 * OpenAPI path: #/components/schemas/station.Station_Status
 */
export enum StationStatus {
  NotSet = 0,
  Active = 1,
  Inactive = 2,
}

/**
 * OpenAPI path: #/components/schemas/ticket.Ticket_Severity
 */
export enum TicketSeverity {
  NotSet = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}

/**
 * OpenAPI path: #/components/schemas/ticket.Ticket_Status
 */
export enum TicketStatus {
  NotSet = 0,
  Open = 1,
  Closed = 2,
}

/**
 * OpenAPI path: #/components/schemas/ticket.Ticket_Target
 */
export enum TicketTarget {
  NotSet = 0,
  Vehicle = 1,
  Parking = 2,
  Station = 3,
  User = 4,
  Ticket = 5,
  Trip = 6,
  Rental = 7,
  Benefit = 8,
  Offer = 9,
  Area = 10,
}

/**
 * OpenAPI path: #/components/schemas/ticket.Ticket_Type
 */
export enum TicketType {
  NotSet = 0,
  Note = 1,
  Issue = 2,
  Action = 3,
}

/**
 * OpenAPI path: #/components/schemas/user.APIKeyStatus
 */
export enum ApiKeyStatus {
  NotSet = 0,
  Active = 1,
  Inactive = 2,
  Deleted = 3,
}

/**
 * OpenAPI path: #/components/schemas/user.Note_NoteStatus
 */
export enum NoteNoteStatus {
  NotSet = 0,
  Active = 1,
  Archived = 2,
  Deleted = 3,
}

/**
 * OpenAPI path: #/components/schemas/user.OptInType
 */
export enum OptInType {
  NotSet = 0,
  TermsAndConditions = 1,
  PrivatePolicy = 2,
  Newsletter = 3,
  InvoiceSubscriptionLeasing = 4,
  InvoiceSubscriptionSharing = 5,
  InvoiceIntentSharing = 6,
  GeolocationSharing = 7,
  GeolocationLeasing = 8,
}

/**
 * OpenAPI path: #/components/schemas/user.RfidProduct_Decoder
 */
export enum RfidProductDecoder {
  NotSet = 0,
  DecToHex = 1,
  InvertByTwoElement = 2,
  DecToHexWith_01AtBegin = 3,
  DecToHexAndInvertByTwoElement = 4,
  DoNothing = 5,
  TrimThreeOutOfThirteenAndConvertToHexa = 6,
}

/**
 * OpenAPI path: #/components/schemas/user.RfidProduct_Technology
 */
export enum RfidProductTechnology {
  CardTypeNone = 0,
  CardTypeMifare = 1,
  CardTypeIso14443B = 2,
  CardTypeIso15693 = 3,
  CardTypeLegic = 4,
  CardTypeHidiclass = 5,
  CardTypeFelica = 6,
  CardTypeSrx = 7,
}

/**
 * OpenAPI path: #/components/schemas/user.RoleStatus
 */
export enum RoleStatus {
  NotSet = 0,
  RoleSoftDeleted = 1,
}

/**
 * OpenAPI path: #/components/schemas/user.User_Avatar
 */
export enum UserAvatar {
  NotSet = 0,
}

/**
 * OpenAPI path: #/components/schemas/user.User_PlaceIcon
 */
export enum UserPlaceIcon {
  PlaceiconNotSet = 0,
  PlaceiconHome = 1,
  PlaceiconOffice = 2,
  PlaceiconPlace = 3,
}

/**
 * OpenAPI path: #/components/schemas/user.User_Sex
 */
export enum UserSex {
  NotSet = 0,
  Male = 1,
  Female = 2,
}

/**
 * OpenAPI path: #/components/schemas/user.User_Status
 */
export enum UserStatus {
  NotSet = 0,
  Inactive = 1,
  HasBooking = 2,
  InTrip = 3,
  InPause = 4,
  Suspended = 5,
  NeedsPayment = 6,
  Anonymized = 7,
}

/**
 * OpenAPI path: #/components/schemas/webhook.PublisherConfig_Type
 */
export enum PublisherConfigType {
  PublisherTypeUndefined = 0,
  Http = 1,
  Bigquery = 2,
  Mail = 3,
}

/**
 * OpenAPI path: #/components/schemas/webhook.Webhook_Status
 */
export enum WebhookStatus {
  Undefined = 0,
  Inactive = 1,
  Active = 2,
}

/**
 * compatibility between different Core API version
 */